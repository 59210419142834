import styled from "styled-components";
import { brandOrange } from "./Colors";
import { bodyTextStyles, headingStyles } from "./Typography";

export const StyledMarkdown = styled.div`
  ${bodyTextStyles};

  h1 {
    ${headingStyles}
    font-size: 75px;
    margin-bottom: 16px;
  }
  h2 {
    ${headingStyles}
    font-size: 40px;
    text-transform: uppercase;
    margin-bottom: 16px;
  }
  h3 {
    ${headingStyles}
    font-size: 29px;
    margin-bottom: 16px;
  }
  h4 {
    ${headingStyles}
    font-size: 23px;
    margin-bottom: 16px;
  }
  h5 {
    ${headingStyles}
    font-size: 22px;
    margin-bottom: 16px;
  }
  h6 {
    ${headingStyles}
    font-size: 18px;
    margin-bottom: 16px;
  }

  p {
    margin-bottom: 16px;
  }

  strong {
    font-weight: 700;
  }

  a {
    color: ${brandOrange};
    text-decoration: none;
  }

  ul,
  ol {
    margin-top: 18px;
    margin-bottom: 18px;
    padding-left: 40px;
  }
  ul {
    list-style: disc;
  }
  ol {
    list-style: decimal;
  }

  blockquote,
  q {
    quotes: unset;
    margin: 18px 40px;
  }

  table {
    border-collapse: separate;
    border-spacing: 2px;
  }

  th {
    font-weight: bold;
  }

  img {
    width: 100%;
  }
  
  sub {
    vertical-align: sub;
    font-size: smaller;
  }
  
  sup {
    vertical-align: super;
    font-size: smaller;
  }
`;
