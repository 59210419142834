import * as React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

import { Layout } from "../components/Layout";
import Seo from "../components/SEO";
import { PostCardDate } from "../components/PostCard";
import { text } from "../components/Colors";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { StyledMarkdown } from "../components/StyledMarkdown";
import { Heading3 } from "../components/Typography";

const StyledPost = styled.article`
  max-width: 774px;
`;

const StyledPostDate = styled(PostCardDate)`
  margin-bottom: 10px;
  display: inline-block;
  :after {
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    height: 1px;
    width: 66px;
    left: calc(100% + 7px);
    background-color: ${text};
  }
`;

const StyledTitle = styled(Heading3)`
  margin-bottom: 20px;
`;

const FeaturedImage = styled(GatsbyImage)`
  margin-bottom: 45px;
`;

const PostTemplate = ({ data }) => {
  const post = data.markdownRemark;
  const title = post.frontmatter.title;
  const featuredImage = post.frontmatter.featuredImage;

  return (
    <Layout>
      <Seo
        title={title}
        description={post.frontmatter.description || post.excerpt}
      />
      <StyledPost>
        <StyledPostDate>{post.frontmatter.date}</StyledPostDate>
        <StyledTitle>{title}</StyledTitle>
        {featuredImage && (
          <FeaturedImage image={getImage(featuredImage)} alt={title} />
        )}
        <StyledMarkdown
          dangerouslySetInnerHTML={{
            __html: post.html,
          }}
          itemProp="description"
        />
      </StyledPost>
    </Layout>
  );
};

export default PostTemplate;

export const pageQuery = graphql`
  query PostBySlug($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "DD.MM.YYYY.")
        description
        featuredImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    }
  }
`;
